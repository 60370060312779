import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { AssessmentStatus } from 'redux/mat-state';
import { assessmentConnector, WithAssessment } from 'redux/slices/assessment-slice';
import { Stepper } from 'shared-components/stepper';
import { setAssessment } from '../app-slice';
import Outline from '../outline/index';
import type { RootState } from '../redux/create-store';
import { useSelector } from '../redux/create-store';
import VoteBlock from './vote-block/vote-block';
import './vote-page.scss';
import { VoteWait } from './vote-wait';
import {VotePaging} from './vote-paging';

type GetAssessmentProps = WithAssessment;

const getShowVoteWaitPage = (state: RootState) => {
  return state.app.showVoteWaitPage;
}

const VotePage: React.FC<GetAssessmentProps> = (props) => {
  const { getAssessment } = props;
  const { assessmentId }= useParams();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(setAssessment({ id: assessmentId }));
    if (assessmentId) {
      getAssessment({ assessmentId, instance }); 
    }
  }, []);

  const assessmentStatus: AssessmentStatus = useSelector((root) => root.app.currentAssessmentStatus);
  if (assessmentStatus?.completionTime) {
    navigate('/assessment/submitted');
  } 
  const endDate: number = useSelector((root) => root.assessment.data.filter(a => a.id == assessmentId).map(a => a.endDate)[0]);
  if (endDate && endDate < Date.now() / 1000) {
    navigate('/assessment/expired');
  }


  const showVoteWaitPage = useSelector(getShowVoteWaitPage);

  return (
    <>
      <div className='mat-vote-page__progressBar'>
        <Stepper />
      </div>
      <div className='mat-vote-page__outline'>
        {assessmentId && <Outline assessmentId={assessmentId}/>} 
      </div>
        
      { showVoteWaitPage ? 
        <div className='mat-vote-page__main'><VoteWait /></div> :
        <>
          <div className='mat-vote-page__main' >
            <VoteBlock />
          </div>
        </>
      }
      <div className='mat-vote-page__footer'>
        <VotePaging/>
      </div>
    </>
  )
}

export default assessmentConnector(VotePage);