
import { useMsal } from '@azure/msal-react';
import { WithApp, appConnector } from 'app-slice';
import { useEffect } from 'react';

type AppProps = WithApp;

export const LoadUser: React.FC<AppProps> = (props: AppProps) => {
  const { instance } = useMsal();
  useEffect(() => {
    const { loginUser } = props;
    loginUser({instance});
  }, []);
  
  return null;
}

export default appConnector(LoadUser);
