type RadioButtonProps = {
  isChecked: boolean;
  isDisabled: boolean;
};

export const RadioButton = ({ isChecked, isDisabled }: RadioButtonProps) => {
  const circlePosition = { cx: '8', cy: '8' }

  if (isChecked) {
    const color: string = isDisabled ? '#808080' : '#4CAF50'
    return (
      <div className='radio-button'>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle {...circlePosition} r='7.5' fill='white' stroke='#BCC2CE' />
          <circle {...circlePosition} r='6.5' stroke='black' />
          <circle {...circlePosition} r='4' fill={color} />
        </svg>
      </div>
    );
  } else {
    return (
      <div className='radio-button'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <circle {...circlePosition} r='7.5' fill='white' stroke='#BCC2CE' />
        </svg>
      </div>
    );
  }
};