import * as _ from 'lodash';
import { useMemo } from 'react';
import AssessmentCard from '../../components/assessment-card';
import { Assessment } from '../../redux/mat-state';
import './assessmentList.scss';

type Image = {
  src: string;
  alt: string;
};

type Props = {
  list: Assessment[];
};

// Assuming assessments array length is always 3, use these labels for display
const assessmentLabels: string[] = ['In Progress', 'Coming Up', 'Recently Completed'];

// TODO: Use as a utility in centralized file
const getDateFromTimestamp = (timestamp: string) => {
  return new Date(timestamp).setUTCHours(0, 0, 0, 0);
};

// Gets Start of Today in UTC
const getStandardStartUTCDate = () => {
  return new Date().setUTCHours(0, 0, 0, 0);
}

// Gets End of Today in UTC
const getStandardEndUTCDate = () => {
  return new Date().setUTCHours(23, 59, 59, 0);
}

export const AssessmentList = ({list}: Props) => {
  // re-filter assessments whenever list props changes; assessments should always have a length of 3
  const assessments = useMemo(() => {
    const today = new Date().setUTCHours(0, 0, 0, 0)

    const inProgress = _.filter(list, (assessment) => today >= getDateFromTimestamp(assessment.startDate) && today <= getDateFromTimestamp(assessment.endDate));
    const coming = _.filter(list, (assessment) => today < getDateFromTimestamp(assessment.startDate));
    const past = _.filter(list, (assessment) => today > getDateFromTimestamp(assessment.endDate));

    return [inProgress, coming, past];
  }, [list]);

  return (
    <div>
      {
        <>
          {assessments.map((sublist, index) => {
            if (sublist != null && sublist.length > 0) {
              return <div key={`assessmentList-${assessmentLabels[index]}`}>
                <h3>{assessmentLabels[index]}</h3>
                <hr className='mat-lp-fac__info-hr' />
                {sublist.map((assessment) => {
                  return <AssessmentCard key={`assessment-${assessment.id}`} assessment={assessment}/>
                })}
              </div>
            } else {
              return <div key={`assessmentList-${assessmentLabels[index]}`}>
                <h3>{assessmentLabels[index]}</h3>
                <hr className='mat-lp-fac__info-hr' />
                <p>
                  <span className='mat-ip-fac__info-key'>No assessment found</span>
                </p>
              </div>
            }
          })}
          <div style={{clear: 'left'}}></div>
        </>
      }
    </div>
  );
};
