import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import Outline from '../../outline';
import { RootState } from '../../redux/create-store';
import { UserType } from '../../redux/mat-state';
import './assessment-welcome.scss';

const getUserType = (state: RootState): string | undefined => state.login.userType

const getUserId = (state: RootState): string | undefined => state.app.userId

const getAssessmentId = (state: RootState): string | undefined => state.app.assessmentId

export const AssessmentWelcome = () => {
  const [pageTriggered, setPageTriggered] = useState<number>(0)
  const userType = useSelector(getUserType);
  const userId = useSelector(getUserId);
  const assessmentId = useSelector(getAssessmentId);

  const navigate = useNavigate();

  return (<>
    <h2 className='mat-assessment-welcome__greeting'>
            Welcome!
    </h2>
    <div className='mat-assessment-welcome__overview'>
      <h3 className='mat-assessment-welcome__legend'>
                Assessment Overview
      </h3>
      <div className='mat-assessment-welcome__card_outline'>
        {(pageTriggered === 2) ? (<div className='mat-assessment-welcome__navigation_box'>
          <div className='mat-assessment-welcome__navigation_box_col1'>
            {(userType === UserType.Facilitator) && (
              <button className='mat-assessment-welcome__navigation_box_btn'
                onClick={() => setPageTriggered(pageTriggered - 1)}>
                <img src='/icons/prev_on.svg' alt='Previous'/>
              </button>)}
          </div>
          <>
            <h4 className='mat-assessment-welcome__card_title'>What will I do?</h4>
            <hr className='mat-assessment-welcome__card_linebreak'/>
            <div className='mat-assessment-welcome__card_content'>
              <div className='mat-assessment-welcome__gp_container'>
                <img src='/images/group141.svg' alt='Group'
                  className='mat-assessment-welcome__gp_image'/>
              </div>
            </div>
            <div className='mat-assessment-welcome__navigation_box_col3'>
              {(userType === UserType.Facilitator) && (<img src='/icons/next_off.svg' alt='Next'/>)}
            </div>
          </>
        </div>) : (pageTriggered === 1) ? (<div className='mat-assessment-welcome__navigation_box'>
          <div className='mat-assessment-welcome__navigation_box_col1'>
            {(userType === UserType.Facilitator) && (
              <button className='mat-assessment-welcome__navigation_box_btn'
                onClick={() => setPageTriggered(pageTriggered - 1)}>
                <img src='/icons/prev_on.svg' alt='Previous'/>
              </button>)}
          </div>
          <div className='mat-assessment-welcome__navigation_box_col2'>
            <h4 className='mat-assessment-welcome__card_title'>What will we talk about?</h4>
            <hr className='mat-assessment-welcome__card_linebreak'/>
            <div className='mat-assessment-welcome__card_content'>
              <div className='mat-assessment-welcome__row'>
                <div className='mat-assessment-welcome__column'>
                  <p>The assessment is broken into different categories. Within the
                                        categories, are a number of sections. Each section speaks to a specific
                                        focus or topic.</p>
                  <p>The outline to the right shows the categories and sections we’ll be going
                                        through today. </p>
                </div>
                <div className="mat-assessment-welcome__column_ll">
                  {assessmentId && <Outline assessmentId={assessmentId}/>}
                </div>
                <div className='mat-assessment-welcome__navigation_box_col3'>
                  {(userType === UserType.Facilitator) && (
                    <button className='mat-assessment-welcome__navigation_box_btn'
                      onClick={() => setPageTriggered(pageTriggered + 1)}>
                      <img src='/icons/next_on.svg' alt='Next'/>
                    </button>)}
                </div>
              </div>
            </div>
          </div>
        </div>) : (<div className='mat-assessment-welcome__navigation_box'>
          <div className='mat-assessment-welcome__navigation_box_col1'>
            {(userType === UserType.Facilitator) && (<img src='/icons/prev_off.svg' alt='Previous Off'/>)}
          </div>
          <div className='mat-assessment-welcome__navigation_box_col2'>
            <h4 className='mat-assessment-welcome__card_title'>Why am I here?</h4>
            <hr className='mat-assessment-welcome__card_linebreak'/>
            <div className='mat-assessment-welcome__card_content'>
              <div className='mat-assessment-welcome__row'>
                <div className='mat-assessment-welcome__column_intro'>
                  <p>This Maturity Assessment Tool is designed to help your team
                                        understand
                                        how
                                        you feel your company is performing with regards to key focus
                                        areas.</p>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <p>After this assessment, you'll have a better understanding of how your
                                        team
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        views the company's current standing, and you'll be able to identify
                                        specific opportunity areas for growth.</p>
                  <h3>Note: The opinions that you input here are anonymous and the site is
                                        secured.</h3>
                </div>
                <div className='mat-assessment-welcome__column'>
                  <img src='/icons/startup_gp.svg' alt='Start up group'
                    className='mat-assessment-welcome__image'/></div>
              </div>
            </div>
          </div>
          <div className='mat-assessment-welcome__navigation_box_col3'>
            {(userType === UserType.Facilitator) && (
              <button className='mat-assessment-welcome__navigation_box_btn'
                onClick={() => setPageTriggered(pageTriggered + 1)}>
                <img src='/icons/next_on.svg' alt='Next'/>
              </button>)}
          </div>
        </div>)}
      </div>
    </div>
    {(userType === UserType.Facilitator) && (<div className='mat-assessment-welcome__actions'>
      <Button label='Begin Voting' className='p-button-info'
        onClick={() => navigate('/assessment-voting')}/>
    </div>)}
  </>)
}