import { IPublicClientApplication } from '@azure/msal-browser';
import { allAssessmentsLink } from '../services/api-entrypoints';
import { buildApi } from './matApiService';


export async function getAssessments(msalInstance: IPublicClientApplication) {

  return buildApi(msalInstance)
    .read(allAssessmentsLink)
}
