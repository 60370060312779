
export const msalConfig = {
  auth: {
    clientId: 'e0b14138-5e7e-49fe-9e8f-2bc8082caf30',
    authority: 'https://login.microsoftonline.com/0da8deea-a93b-4eb1-97f4-07b264d7dc1e',
    redirectUri: '/login/callback'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
  scopes: [`${msalConfig.auth.clientId}/.default`]
};