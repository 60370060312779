import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildApi } from 'services/matApiService';
import { recordVoteLink } from 'services/api-entrypoints'; 
import { GetVotesResponse, RecordVoteResponse, VoteModel } from 'model';
import { AppDispatch, RootState } from 'redux/create-store';
import { connect, ConnectedProps } from 'react-redux';

// Deprecated - left here because deprecated component assessment-block is using it
export enum VoteType {
  Current = 'CURRENT',
  Future = 'FUTURE'
}

export type Vote = {
  promptResultId: string;
  labelId: string;
};

export type VoteState = {
  [promptId: string]: Vote
};

type RecordVoteArgs = {
  instance: IPublicClientApplication;
  labelId: string;
  promptId: string;
}

type GetVoteByLabelArgs = {
  instance: IPublicClientApplication;
  labelIds: string[];
  promptId: string
}

const initialState: VoteState = {};

export const getVoteByLabel = createAsyncThunk(
  'votesByPromptId/getVoteByLabel',
  async ({ instance, labelIds, promptId }: GetVoteByLabelArgs, thunkAPI) => {
    try {
      const { read: getVotes } = buildApi(instance);

      const result = await Promise.all(
        labelIds.map((labelId) => {
          return getVotes(recordVoteLink(labelId))
            .then((res) => res.json())
            .then((res) => {
              const response = res as GetVotesResponse;
              return response.items.map((item) => {
                return {
                  id: item.id,
                  labelId: labelId,
                  promptId: promptId
                } as VoteModel
              })
            })
        })
      ).then((votesListArray) => {
        return votesListArray.flatMap(v => v)
      })
      return thunkAPI.fulfillWithValue(result);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  })

export const recordVote = createAsyncThunk(
  'votesByPromptId/recordVote',
  async (args: RecordVoteArgs, thunkAPI) => {
    if (!args.instance) {
      return thunkAPI.rejectWithValue(new Error('Error recording the response.'));
    }
    try {
      const {create: postVote} = buildApi(args.instance)
      const result = await postVote(recordVoteLink(args.labelId), {})
        .then((res) => res.json())
        .then((res) => {
          const response = res as RecordVoteResponse

          return {
            id: response.id,
            labelId: args.labelId,
            promptId: args.promptId
          } as VoteModel
        })
      return thunkAPI.fulfillWithValue(result);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  })

export const voteSlice = createSlice({
  name: 'votesByPromptId',
  initialState,
  reducers: { },
  extraReducers(builder) {
    builder.addCase(
      getVoteByLabel.fulfilled,
      (state: VoteState, action) => {
        const votesList = action.payload
          .reduce((votes: VoteState, vote) => {
            votes[vote.promptId] = {
              promptResultId: vote.id,
              labelId: vote.labelId
            } as Vote
            return votes
          }, {})

        return {
          ...state,
          ...votesList
        }
      }
    );
    builder.addCase(
      getVoteByLabel.rejected,
      (state: VoteState, action) => {
        console.log('getVoteByLabel rejected ', action.payload);
      }
    );
    builder.addCase(
      getVoteByLabel.pending,
      (state: VoteState, action) => {
        console.log('getVoteByLabel pending ', action.payload);
      }
    );
    builder.addCase(recordVote.fulfilled, (state, action) => {
      const payload = action.payload;
      const newVote = {
        promptResultId: payload.id,
        labelId: payload.labelId
      } as Vote
      
      return {
        ...state,
        [payload.promptId]: newVote
      } as VoteState
    });
    builder.addCase(recordVote.rejected, (state, action) => {
      console.log(state, action)
    });
  }
});

const mapState = (state: RootState) => state.votesByPromptId;

const mapDispatch = (dispatch: AppDispatch) => ({
  recordVote: (args: RecordVoteArgs) => dispatch(recordVote(args))
})

export const voteConnector = connect(mapState, mapDispatch);
export type WithVote = ConnectedProps<typeof voteConnector>;
