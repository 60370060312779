import { useSelector } from 'react-redux';
import type { RootState } from '../redux/create-store';
import './stepper.scss';

const getAmountOfCategories = (state: RootState) => {
  return state.categories.allIds.length;
}

const getCurrentCategoryIndex = (state: RootState) => {
  const categoryId = state.app.currentCategory;
  return categoryId ? state.categories.allIds.indexOf(categoryId) + 1 : 1;
}

export const Stepper = () => {
  const amountOfCategories = useSelector(getAmountOfCategories);
  const currentCategoryIndex = useSelector(getCurrentCategoryIndex);

  return (
    <div>
      <div className='mat-stepper__parts' data-testid='progressBar'>Part {currentCategoryIndex} of {amountOfCategories}</div>
      <div className='mat-stepper__progressBar'>
        <div className='mat-stepper__progressValue' style={{width: `${(currentCategoryIndex / amountOfCategories) * 100}%`}} />
      </div>
    </div>
  )
}