import { AssessmentInfoPage, Icon } from './assessment-info-page/assessment-info-page';


export const AssessmentSubmittedPage = () => {
  const icon = Icon.Thumbs_up;
  const header = 'Thank you for participating!'
  const text = 'Your completed assessment has been submitted. We appreciate your time and attention.';
  return (<>
    <AssessmentInfoPage icon={icon} header={header} text={text}/>
  </>)
}