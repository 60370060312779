import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { loginRequest } from './authConfig';

/**
 * Verifies authentication status and redirects to SSO if needed
 */
export const LoginRedirect = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (inProgress == InteractionStatus.None && !isAuthenticated) {
    console.log('calling sign in');
    const handleSignIn = async () => {
      sessionStorage.setItem('nextUrl', location.pathname)
      await instance.loginRedirect(loginRequest)
        .catch((e) => {
          console.error(e);
        })
    }

    handleSignIn();
  }

  return null;
}
