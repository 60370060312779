import {useIsAuthenticated} from '@azure/msal-react';
import FacilitatorLP from './facilitatorLP';
import './landing-page.scss';


export const LandingPage = () => {

  return (
    <>
      <div className='mat-wr__main'>
      
        <FacilitatorLP />

      </div>
    </>
  );
};
