/**
 * This file contains models stored in the redux store
 */

import { AssessmentState } from './slices/assessment-slice';
import { AppState } from '../app-slice';
import { PromptDataState } from '../assessment/prompt-data-slice';
import { SectionDataState } from '../assessment/section-data-slice';
import { LoginState } from './slices/login-slice';
import { SectionState } from '../redux/slices/section-slice';
import { CategoryState } from './slices/category-slice';
import { PromptState } from './slices/prompt-slice';
import { LabelState } from './slices/label-slice';

export enum UserType {
    Participant = 'PARTICIPANT',
    Facilitator = 'FACILITATOR'
}

export type Assessment = {
    id: string
    name: string
    description: string
    client: string
    sponsor: string
    createdDate: string
    finalizationDate: string
    startDate: string
    endDate: string
}

export type AssessmentStatus = {
    id: string
    completionTime: string
    startTime: string
}

export type Category = {
    id: string
    name: string
    icon: string
    order: number
    assessmentId: string
}

export type Label = {
    id: string
    label: string
    order: number
    weight: number
    promptId: string
}

export type Prompt = {
    id: string
    text: string
    order: number
    sectionId: string
}

export type Section = {
    id: string
    name: string
    description: string
    order: number
    categoryId: string
}

export type MatState = {
    app: AppState
    assessment: AssessmentState
    categories: CategoryState
    sections: SectionState
    prompts: PromptState
    labels: LabelState
    promptData: PromptDataState
    sectionData: SectionDataState
    votesByPromptId: any
    login: LoginState
}

export type VotesByPromptIdType = MatState['votesByPromptId'];
