import './vote-wait.scss';

export const VoteWait = () => {
  return (
    <>
      <div className='mat-vw__outline'/>
      <div className='mat-vw__title'>
        <img
          alt='Thumbs Up'
          src='/icons/thumbs_up.svg'
          className='mat-vw__image'
        />
      </div>
      <div className='mat-vw__main'>
        <h4>You&apos;ve finished!</h4>
        <h4>We&apos;ll wait here for others to finish.</h4>
      </div>
    </>
  );
};
