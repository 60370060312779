import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoadUser from 'authentication/load-user';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './app.scss';
import { AssessmentExpiredPage } from './assessment/assessment-info-pages/assessment-expired-page';
import { AssessmentSubmittedPage } from './assessment/assessment-info-pages/assessment-submitted-page';
import { AssessmentVoting } from './assessment/assessment-voting/assessment-voting';
import { AssessmentWelcome } from './assessment/assessment-welcome/assessment-welcome';
import { LoginCallback } from './authentication/callkback';
import { LoginRedirect } from './authentication/login-redirect';
import CreateAssessment from './components/assessment/CreateAssessment';
import Grid from './grid/grid';
import { LandingPage } from './landing-page/landing-page';
import { Header } from './shared-components/header';
import VotePage from './vote-page/vote-page';
import { WaitingRoom } from './waiting-room/waiting-room';

function App() {
  return (
    <Router>
      <div className='mat-app'>
        <LoginRedirect/>
        <Header />
        <AuthenticatedTemplate>
          <LoadUser/>
          <Routes>
            <Route path="/assessment/create_assessment" element={<CreateAssessment />} />

            <Route
              path="/assessment/waiting_room/:assessmentId"
              element={<WaitingRoom />}
            />
            <Route
              path="/assessment/prompt_response/:assessmentId"
              element={<VotePage />}
            />
            <Route path='/assessment' element={<AssessmentWelcome />} />
            <Route path='/assessment-voting' element={<AssessmentVoting />} />
            <Route path='/grid' element={<Grid />} />
            <Route path='/' element={<LandingPage />} />
            <Route path='/login/callback' element={<LoginCallback />} />
            <Route path='/' element={ <LandingPage/> }/>
            <Route path='/assessment/submitted' element={ <AssessmentSubmittedPage/> }/>
            <Route path='/assessment/expired' element= { <AssessmentExpiredPage/> }/>
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path='/login/callback' element={<LoginCallback />} />
          </Routes>
        </UnauthenticatedTemplate>
      </div>
    </Router>
  );
}

export default App;
