import { useDispatch, useSelector } from 'react-redux';
import {
  changeCurrentCategory,
  changeCurrentPrompt,
  changeCurrentSection,
  setCurrentAssessmentStatus,
  showVoteWaitPage,
} from '../app-slice';
import type { RootState } from '../redux/create-store';
import './vote-paging.scss';
import { useMsal } from '@azure/msal-react';
import { buildApi } from 'services/matApiService';
import { assessmentStatusLink } from 'services/api-entrypoints';
import { AssessmentStatusResponse } from 'services/links';
import { AssessmentStatus } from 'redux/mat-state';

type Image = {
  src: string;
  alt: string;
};

const getVotesByPromptId = (state: RootState) => {
  return state.votesByPromptId;
};

const getApp = (state: RootState) => {
  return state.app;
};

const getCurrentPromptsInOrder = (state: RootState) => {
  return state.prompts;
};

const getCurrentSectionsIdsInOrder = (state: RootState) => {
  return state.sections;
};

export const VotePaging = () => {
  const dispatch = useDispatch();
  const {instance} = useMsal();

  const prompts = useSelector(getCurrentPromptsInOrder);
  const sections = useSelector(getCurrentSectionsIdsInOrder);
  const numberOfPrompts = prompts.allIds.length;
  const votesSubmitted = useSelector(getVotesByPromptId);
  const app = useSelector(getApp);

  const isCompleted = !!app.currentAssessmentStatus?.completionTime;
  const isLastPrompt = app.currentPrompt === prompts.allIds[numberOfPrompts - 1];

  const onNavigate = (value: string, direction: string) => {
    // take value/id passed in and get index in sectionPromptIds
    let indexOfPrompt = prompts.allIds.findIndex((id) => value === id);
    if (indexOfPrompt !== -1) {
      if (direction === 'up' && indexOfPrompt + 1 !== numberOfPrompts) {
        indexOfPrompt++;
      }
      if (direction === 'down' && indexOfPrompt !== 0) {
        indexOfPrompt--;
      }
      // set index then set currentPromptId in state
      const nextPromptId = prompts.allIds[indexOfPrompt];
      dispatch(changeCurrentPrompt({ promptId: nextPromptId }));
      const nextSectionId = prompts.byId[nextPromptId].sectionId;
      if (app.currentSection !== nextSectionId) {
        dispatch(changeCurrentSection({ sectionId: nextSectionId }));
        const nextCategoryId = sections.byId[nextSectionId].categoryId;
        if (app.currentCategory !== nextCategoryId) {
          dispatch(changeCurrentCategory({ categoryId: nextCategoryId }));
        }
      }
    }
  };

  const completeAssessment = async () =>{
    dispatch(
      showVoteWaitPage({
        showVoteWaitPage: true,
        sectionId: app.currentSection!,
        voteType: app.currentVoteState!,
      })
    );
    if (app.currentAssessmentStatus?.id) {
      const {update: completeAssessment} = buildApi(instance)
      const response = await completeAssessment(assessmentStatusLink(app.currentAssessmentStatus?.id), { completionTime: new Date().getTime()/1000 });

      if (response.ok) {
        const responseObj = await response.json() as AssessmentStatusResponse
        if (responseObj){
          const assessmentStatus =  {
            id: responseObj.id,
            startTime: responseObj.startTime,
            completionTime: responseObj.completionTime
          } as AssessmentStatus;
          dispatch(setCurrentAssessmentStatus(assessmentStatus));
        }
      }
    }
  }

  return (
    <>
      {app.currentPrompt ? (
        <div className='mat-vote-paging__btn-nav-container'>
          {/*<button*/}
          {/*  className='mat-vote-paging__button mat-vote-paging__button__leave-assessment'*/}
          {/*>*/}
          {/*  <span className='pi pi-sign-out leave-assessment-button-icon'></span>*/}
          {/*  Leave Assessment*/}
          {/*</button>*/}

          <div>
            <button
              className='mat-vote-paging__button mat-vote-paging__button__previous'
              onClick={() => onNavigate(app.currentPrompt!, 'down')}
              disabled={app.currentPrompt === prompts.allIds[0] ? true : false}
            >
              Previous
            </button>
            {isLastPrompt && isCompleted ? null :
              isLastPrompt ? (
                <button
                  disabled={numberOfPrompts !== Object.keys(votesSubmitted).length}
                  onClick={completeAssessment}
                  //TODO: reorganize state so that these are required where necessary
                  className='mat-vote-paging__button mat-vote-paging__button__next'
                >
                  Submit
                </button>
              ) : (
                <button
                  className='mat-vote-paging__button mat-vote-paging__button__next'
                  onClick={() => onNavigate(app.currentPrompt!, 'up')}
                >
                  Next
                </button>
              )}
          </div>
        </div>
      ) : null}
    </>
  );
};
