import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { changeCurrentPrompt } from '../../app-slice';
import { GetPromptsResponse } from '../../model';
import { promptBySectionLink } from '../../services/api-entrypoints';
import { buildApi } from '../../services/matApiService';
import { ApiVersion } from '../../services/media-type-resource-version';
import { AppDispatch, RootState } from '../create-store';
import { Prompt } from '../mat-state';
import { getLabelByPrompt } from './label-slice';

export type PromptState = {
  byId: {
    [id: string]: Prompt;
  };
  allIds: string[];
};

const initialState: PromptState = {
  byId: {},
  allIds: [],
};

type GetPromptBySectionArgs = {
  instance: IPublicClientApplication;
  sectionIds: string[];
}

export const getPromptBySection = createAsyncThunk<
  Prompt[],
  { instance: IPublicClientApplication; sectionIds: string[] }
>('prompt/getPromptBySection', async ({ instance, sectionIds }: GetPromptBySectionArgs, thunkApi) => {
  try {
    const { read: getPrompts } = buildApi(instance, ApiVersion.V3_JSON);

    const result = await Promise.all(
      sectionIds.map((sectionId) => {
        return getPrompts(promptBySectionLink(sectionId.toString()))
          .then((res) => res.json())
          .then((res) => {
            const response = res as GetPromptsResponse;
            return response.items
              .sort((p1, p2) => p1.order - p2.order)
              .map(p => {
                return {
                  id: p.id,
                  text: p.text,
                  order: p.order,
                  sectionId: sectionId
                } as Prompt
              })
          });
      })
    ).then((promptListArray) => {
      const prompts = promptListArray.flatMap(p => p)
      if (promptListArray.length > 0) {
        const promptIds = promptListArray.flatMap((promptList) =>
          promptList.map((item) => item.id)
        );
        thunkApi.dispatch(
          getLabelByPrompt({ instance, promptIds: promptIds })
        );
        console.log(`promptId=${promptIds}`);

        thunkApi.dispatch(changeCurrentPrompt({ promptId: promptIds[0] }));
      }
      return prompts;
    });
    return thunkApi.fulfillWithValue(result);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const promptsSlice = createSlice({
  name: 'prompts',
  initialState,
  reducers: {
    // TODO: Remove once login slice has been refactored
    addPrompts: (state, action: PayloadAction<Prompt[]>) => {
      action.payload.forEach((prompt) => {
        if (!state.byId[prompt.id]) {
          state.allIds.push(prompt.id);
        }
        state.byId[prompt.id] = prompt;
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getPromptBySection.fulfilled,
      (state: PromptState, action) => {
        const promptList = action.payload;
        
        const finalState: PromptState = {
          byId: {},
          allIds: [],
        };

        for (const prompt of promptList) {
          finalState.byId[prompt.id] = prompt;
          finalState.allIds.push(prompt.id);
        }

        state.allIds = finalState.allIds;
        state.byId = finalState.byId;
      }
    );
    builder.addCase(
      getPromptBySection.rejected,
      (state: PromptState, action) => {
        console.log('getPromptBySection rejected ', action.payload);
      }
    );

    builder.addCase(
      getPromptBySection.pending,
      (state: PromptState, action) => {
        console.log('getPromptBySection pending ', action.payload);
      }
    );

  },
});

const mapState = (state: RootState) => state.prompts;

const mapDispatch = (dispatch: AppDispatch) => ({
  getPromptBySection: (args: GetPromptBySectionArgs) => dispatch(getPromptBySection(args))
})

export const promptConnector = connect(mapState, mapDispatch);

export type WithPrompt = ConnectedProps<typeof promptConnector>;

export const { addPrompts } = promptsSlice.actions; // TODO: Remove once login slice has been refactored
