import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, type RootState } from '../redux/create-store';
import { UserType } from '../redux/mat-state';
import FacilitatorMenu from './facilitator-menu';
import './header.scss';

const getUserType = (state: RootState) => {
  return state.login.userType;
}

const getUserName = (state: RootState) => {
  const user = state.app.user;
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  } else {
    return 'Participant One'
  }
}
export const Header = () => {
  const userType = useSelector(getUserType);
  const username: string = useSelector(getUserName);
  const isFacilitator = userType === UserType.Facilitator;
  
  const userText = userType === UserType.Facilitator ? 'Frank North' : username;
  const [showMenu, setShowMenu] = useState(false);
  const userMenu = <FacilitatorMenu toggleMenu={(status) => setShowMenu(status)} showMenu={showMenu}/>;
  const location = useLocation();

  return (
    <div className='mat-header'>
      <img alt='Maturity Assessment Tool logo' src='/MATLogo.jpg' className='mat-header__logo'/>
      { 
        location.pathname === '/login'         
          ? null
          : <div className={`mat-header__user ${isFacilitator ? 'mat-header__user_fac' : ''}`} onClick={() => setShowMenu(!showMenu)}>
            <div className='mat-header__user_icon-background'>
              <img src='/icons/user.svg' alt='user icon' className='mat-header__user_icon'/>
            </div>
            <span className='mat-header__user_text'>
              {userText}
            </span>
            {userMenu}
          </div>
      }
    </div>
  )
}