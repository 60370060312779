import * as React from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../redux/create-store';
import { useNavigate } from 'react-router';
import Tooltip from '@mui/material/Tooltip'
import './wr-facilitator.scss'

const getAssessmentId = (state: RootState) => {
  return state.app.assessmentId;
}

export const FacilitatorWR = () => {
  const navigate = useNavigate();
  const assessmentId = useSelector(getAssessmentId);
  const assessmentLink = `https://mat.daugherty.com/assessment/waiting_room/${assessmentId}`;
  const copyLink = (link: string) => {
    const cb = navigator.clipboard;
    cb.writeText(link);
    handleTooltipOpen();
  }

  const [open, setOpen] = React.useState(false);
  const handleTooltipOpen = () => {
    setOpen(true);
  };


  return (
    <div className="mat-wr-fac">
      <div className="mat-wr-fac__info-container">
        <h3 className="mat-wr-fac__info-title">Business IT Session</h3>
        <hr className="mat-wr-fac__info-hr" />
        <p>
          <span className="mat-wr-fac__info-key">Client: </span>
          <span className="mat-wr-fac__info-value">Edward Jones</span>
        </p>
        <p>
          <span className="mat-wr-fac__info-key">Sponsor: </span>
          <span className="mat-wr-fac__info-value">April Sol</span>
          <span className="mat-wr-fac__info-key"> | </span>
          <span className="mat-wr-fac__info-value">Principal</span>
        </p>
        <p>
          <img className="mat-wr-fac__info-calendar-icon" src="/icons/calendar.svg" alt="calendar icon" />
          <span className="mat-wr-fac__info-value">07/23/2022</span>
          <span className="mat-wr-fac__info-key"> | </span>
          <span className="mat-wr-fac__info-value">10:00 AM</span>
        </p>
      </div>
      <div className="mat-wr-fac__right">
        <div className="mat-wr-fac__card">
          <p className="mat-wr-fac__card_text">
            <span className="mat-wr-fac__card_number-waiting">5</span>
            <br />
            participants in waiting room
          </p>
          <button className="mat-wr-fac__card_btn" onClick={() => navigate('/assessment')}>Begin Assessment</button>
          <hr className="mat-wr-fac__card_divider"/>
          {/* TODO: update link */}
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Link Copied"
            >
              <p onClick={() => copyLink(assessmentLink)} className="mat-wr-fac__card_link">
                Copy Assessment Link
                <img className="mat-wr-fac__card_copy-icon" src="/icons/copy.svg" alt="copy icon" role="button"/>
              </p>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}