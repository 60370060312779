import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export const LoginCallback = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  if (inProgress == InteractionStatus.None && isAuthenticated) {
    const next = sessionStorage.getItem('nextUrl');
    if (next != null) {
      sessionStorage.removeItem('nextUrl');
      navigate(next);
    }
  }

  return null;
};