/** This file is most probably deprecated **/

import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../redux/create-store';
import { VoteType } from '../../vote-page/vote-slice';
import { createGetUsersFinishedWithPromptSelector, getAllUsersSelector } from '../prompt-data-slice';
import { createGetUsersFinishedWithSectionSelector } from '../section-data-slice';

import './assessment-block.scss';

type Image = {
    src: string,
    alt: string
};

const getCurrentSectionId = (state: RootState) => {
  return state.app.currentSection;
}

export const AssessmentBlock = (props: { numberOfPrompts: number}) => {
  const { numberOfPrompts } = props;

  const allUsers = useSelector(getAllUsersSelector, shallowEqual);
  const numberOfParticipants = allUsers.length;

  return (
    <div className='mat-assessment' style={{'--number-of-participants': numberOfParticipants} as React.CSSProperties}>
      <div className='mat-assessment__grid'>
        <div className='mat-assessment__grid-header'>Questions</div>
        <div className='mat-assessment__grid-header'>Participant Progress</div> 

        <div className='mat-assessment__grid-gap'>&nbsp;</div>
        <div className='mat-assessment__grid-participants'>
          {allUsers.map((_, index) => (<div key={`participant-header=${index}`} className='mat-assessment__grid-participant'>{index + 1}</div>))}
        </div>

        {[ ...Array(numberOfPrompts) ].map((_, index) => (<Row key={`prompt-row-${index}`} rowNumber={index + 1} />))}

      </div>
    </div>
        
  )
}

export const Row = ({ rowNumber }: { rowNumber: number }) => {
  const promptId = rowNumber; // TODO: use the actual promptId
  const sectionId = useSelector(getCurrentSectionId);
  const allUsers = useSelector(getAllUsersSelector, shallowEqual);

  const getUsersFinishedWithPromptSelector = useMemo(() => createGetUsersFinishedWithPromptSelector(promptId, VoteType.Current), [promptId]);
  const getUsersFinishedWithSectionSelector = useMemo(() => createGetUsersFinishedWithSectionSelector(sectionId, VoteType.Current), [sectionId])
    
  const usersFinishedWithPrompt = useSelector(getUsersFinishedWithPromptSelector);
  const usersFinishedWithSection = useSelector(getUsersFinishedWithSectionSelector);

  const imgCompleted: Image = { src: 'icons/progress-completed.svg', alt: 'completed'};
  const imgNotStarted: Image = { src: 'icons/progress-not-started.svg', alt: 'not started'};
  const imgLocked: Image = { src: 'icons/progress-locked.svg', alt: 'section locked'};

  return (<>
    <div className='mat-assessment__questions'>
      <div className='mat-assessment__questions-number'>{rowNumber}</div>
      <div className='mat-assessment__questions-text'>We’ve established and shared a future state vision of product excellence, with clear and measureable outcomes.</div>
    </div>
    <div className='mat-assessment__progress'>
      {allUsers.map(userId => {
        const didComplete = usersFinishedWithPrompt.includes(userId);
        const didLock = usersFinishedWithSection.includes(userId);
        return (
          <div key={`participant-row-${rowNumber}-${userId}`} className='mat-assessment__result'>
            {
              !didLock 
                ? !didComplete 
                  ? <img src={imgNotStarted.src} alt={imgNotStarted.alt} />
                  : <img src={imgCompleted.src} alt={imgCompleted.alt} />
                                
                : <img src={imgLocked.src} alt={imgLocked.alt} />
            }                        
          </div>
        );
      })}
    </div>
  </>);
};