import './wr-participant.scss';

export const ParticipantWR = () => {
  return (
    <>
      <div className='mat-wr-participant__outline'/>
      <div className='mat-wr-participant__title'>
        <img
          src='/dbsLogoColor.svg'
          className='mat-wr-participant__logo'
          alt='Daugherty Business Solutions logo'
        />
      </div>
      <div className='mat-wr-participant__main'>
        <h3>Thanks for joining!</h3>
        <h3>The assessment will begin shortly.</h3>
      </div>
    </>
  );
};