import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeCurrentSection } from '../../app-slice';
import { GetSectionsResponse } from '../../model';
import { Section } from '../mat-state';
import { getPromptBySection } from './prompt-slice';
import { sectionByCategoryLink } from '../../services/api-entrypoints';
import { buildApi } from '../../services/matApiService';
import { ApiVersion } from '../../services/media-type-resource-version';

export type SectionState = {
  byId: {
    [id: string]: Section;
  };
  allIds: string[];
};

const initialState: SectionState = {
  byId: {},
  allIds: [],
};

export const getSectionsByCategories = createAsyncThunk<
Section[],
  { instance: IPublicClientApplication; categoryIds: string[] }
>(
  'section/getSectionsByCategories',
  async ({ instance, categoryIds }, thunkApi) => {
    const { read: getSectionsByCategories } = buildApi(
      instance,
      ApiVersion.V3_JSON
    );

    const result = await Promise.all(
      categoryIds.map((categoryId) => {
        return getSectionsByCategories(
          sectionByCategoryLink(categoryId.toString())
        )
          .then((res) => res.json())
          .then((res) => {
            const response = res as GetSectionsResponse;
            return response.items
              .sort((s1, s2) => s1.order - s2.order)
              .map(s => {
                return {
                  id: s.id,
                  name: s.name,
                  description: s.description,
                  order: s.order,
                  categoryId: categoryId
                } as Section
              });
          });
      })
    ).then((sectionListArray) => {
      const sections = sectionListArray.flatMap(s => s)
      if (sectionListArray.length > 0) {
        const sectionIds = sectionListArray.flatMap((sectionList) =>
          sectionList.map((item) => item.id)
        );
        thunkApi.dispatch(
          getPromptBySection({ instance, sectionIds: sectionIds })
        );
        thunkApi.dispatch(changeCurrentSection({ sectionId: sectionIds[0] }));
      }
      return sections;
    });

    if (result.length > 0) {
      return thunkApi.fulfillWithValue(result);
    } else {
      return thunkApi.rejectWithValue(
        new Error('Could not pull the categories')
      );
    }
  }
);

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    addSections: (state, action: PayloadAction<Section[]>) => {
      action.payload.forEach((section) => {
        if (!state.byId[section.id]) {
          state.allIds.push(section.id);
        }
        state.byId[section.id] = section;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSectionsByCategories.fulfilled,
      (state: SectionState, action: PayloadAction<Section[]>) => {
        const sectionList = action.payload;
        
        const finalState: SectionState = {
          byId: {},
          allIds: [],
        };

        for (const section of sectionList) {
          finalState.byId[section.id] = section;
          finalState.allIds.push(section.id);
        }

        state.allIds = finalState.allIds;
        state.byId = finalState.byId;
      }
    );
    builder.addCase(getSectionsByCategories.pending, (state, action) => {
      console.log('Getting sections pending...');
    });
    builder.addCase(getSectionsByCategories.rejected, (state, action) => {
      console.warn(action.payload);
    });
  },
});

export const { addSections } = sectionsSlice.actions;
