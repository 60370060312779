import {IPublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from '../authentication/authConfig';
import {HateoasResponse, Link, Links} from './links'
import {ApiVersion} from './media-type-resource-version'


export type ModelType<R> = R extends HateoasResponse<infer T, any> ? T : never

type ResponseType<L> = L extends Link<infer R> ? R extends Array<infer Item> ? {
    items: Item[],
    _links: Links<R>
} : R : never

export type TypedResponse<L extends Link<any>> = ({ ok: true } & { json(): Promise<ResponseType<L>> } & Omit<Response, 'json'>) | ({ ok: false } & Response)

type SingleLink<R> = R extends Array<infer T>
    ? T extends HateoasResponse<any, any>
        ? Link<T>
        : never
    : never

type MethodAwareTypedResponse<M, L extends Link<any>> = M extends 'POST'
    ? L extends Link<infer R>
        ? TypedResponse<SingleLink<R>>
        : never
    : TypedResponse<L>

const fetchFromLink = (msalInstance: IPublicClientApplication, version: ApiVersion) => <L extends Link<any>,>(link: L) => {
  return doFetch(link, msalInstance, version)
}

const postToLink = (msalInstance: IPublicClientApplication, version: ApiVersion) => <R extends HateoasResponse<any, any>>(link: Link<R[]>, value: Partial<ModelType<R>> & { id?: undefined }) => {
  return doFetch(link, msalInstance, version, 'POST', value)
}

const patchAtLink = (msalInstance: IPublicClientApplication, version: ApiVersion) => <R extends HateoasResponse<any, any>>(link: Link<R>, patchDocument: Partial<ModelType<R>> & { id?: undefined }) => {
  return doFetch(link, msalInstance, version, 'PATCH', patchDocument)
}

const deleteAtLink = (msalInstance: IPublicClientApplication, version: ApiVersion) => <R extends HateoasResponse<any, any>>(link: Link<R>) => {
  return doFetch(link, msalInstance, version, 'DELETE')
}

const doFetch = async <M extends string, L extends Link<any>>(link: L, msalInstance: IPublicClientApplication, version: ApiVersion, method?: M, body?: any): Promise<MethodAwareTypedResponse<M, L>> => {
  const token = await getAccessToken(msalInstance)

  const headers: Record<string, string> = {
    Authorization: `Bearer ${token}`,
    Accept: version.toString()
  }

  if (body) {
    headers['Content-Type'] = 'application/json'
  }

  return fetch(link.href, {
    headers,
    method,
    body: body && JSON.stringify(body)
  }) as Promise<MethodAwareTypedResponse<M, L>>
}

async function getAccessToken(msalInstance: IPublicClientApplication) {

  const account = msalInstance.getAllAccounts()[0];

  if (!account) {
    console.log('Account is unavailable', account)
    return;
  }

  const tokenRequest = {
    scopes: [`${msalConfig.auth.clientId}/.default`],
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.accessToken;
  } catch (error) {
    console.error('Error acquiring token:', error);
  }
}

export const buildApi = (msalInstance: IPublicClientApplication, version: ApiVersion = ApiVersion.V3_JSON) => ({
  create: postToLink(msalInstance, version),
  read: fetchFromLink(msalInstance, version),
  update: patchAtLink(msalInstance, version),
  delete: deleteAtLink(msalInstance, version),
})
