import { useSelector } from 'redux/create-store'
import { RadioButton } from './radio-button'
import './radio-list.scss'
import { AssessmentStatus } from 'redux/mat-state'

type RadioElement = {
  label: string,
  id: string
}

type RadioProps = {
  elements: RadioElement[]
  selectedValue?: string
  setSelectedValue(value: string): void
}

export const RadioList = ({ elements, selectedValue, setSelectedValue }: RadioProps) => {
  const assessmentStatus: AssessmentStatus = useSelector((mat) => mat.app.currentAssessmentStatus);
  const isCompleted = !!assessmentStatus?.completionTime;
  return (
    <div>
      {elements.map((element, index) => {
        return (
          <div className="radio-list__item"
            key={index}
            onClick={ (event) => {
              event.preventDefault();
              setSelectedValue(element.id); }
            }
          >
            <input
              key={'option' + index}
              type='radio'
              id={'option-' + index}
              name="selfRatingOption"
              checked={element.id == selectedValue}
              disabled={isCompleted}
              readOnly
            />
            <RadioButton isChecked={element.id == selectedValue} isDisabled={isCompleted} />
            <label 
              className="radio-list__label"
              htmlFor={'option-' + index}
              data-testid={element.id == selectedValue ? 'prompt-label-text-selected' : 'prompt-label-text'}
            >
              {element.label}
            </label>
          </div>);
      })}
    </div>
  )
};