import { useMsal } from '@azure/msal-react';
import { Link, useLocation } from 'react-router-dom';

type FacilitatorMenuProps = {
    showMenu: boolean
    toggleMenu(status: boolean): void
}

const FacilitatorMenu = ({toggleMenu, showMenu}: FacilitatorMenuProps) => {
  const { instance  } = useMsal();
  const location = useLocation();

  const handleLogout = () => {
    instance.logoutRedirect({
      onRedirectNavigate: (url) => {
        return false;
      }
    });
    toggleMenu(false);
  }

  return (
    <span className="mat-header__user_menu">
      <img className="mat-header__user_menu-icon" src="/icons/chevron-down.svg" alt="menu dropdown icon" />
      <div id="myDropdown" className={ `mat-header__user_menu-content ${showMenu ? 'show' : ''}` }>
        <Link to={location.pathname} onClick={() => handleLogout()} onMouseLeave={() => toggleMenu(false)}>Logout</Link>
      </div>
    </span>
  )
}

export default FacilitatorMenu;