/** This file is most probably deprecated **/

import { Card } from 'primereact/card';
import { useSelector } from 'react-redux';
import Outline from '../../outline';
import type { RootState } from '../../redux/create-store';
import { AssessmentBlock } from '../assessment-block/assessment-block';

import './assessment-voting.scss';

const getAssessmentId = (state: RootState): string | undefined => state.app.assessmentId

export const AssessmentVoting = () => {
  const assessmentId = useSelector(getAssessmentId);
  const getVoteState = (state: RootState) => {
    return state.app.currentVoteState;
  };
  const voteState = useSelector(getVoteState) || '';
  const voteStateText =
    voteState && voteState === 'CURRENT'
      ? 'Where do you see the organization today?'
      : voteState && voteState === 'FUTURE'
        ? 'Where does the organization need to be in the future to be competitive?'
        : null;

  return (
    <>
      <div className="mat-assessment-voting__outline">
        {assessmentId && <Outline assessmentId={assessmentId} />}
      </div>

      <div className='mat-assessment-voting__progress'>
        <Card style={{ minWidth: '879px' }}>
          <AssessmentBlock numberOfPrompts={2}></AssessmentBlock>
        </Card>
      </div>

      <div className='mat-assessment-voting__title'>
        <div className='mat-assessment-voting__title-text'>{voteStateText}</div>
      </div>

      <div className='mat-assessment-voting__actions'>
        <button className='mat-assessment-voting__actions-btn'>
          End Voting Session
        </button>
        <div className='mat-assessment-voting__actions-info'>
          <span>Next:</span> View median vote
        </div>
      </div>
    </>
  );
};
