/** This file is most probably deprecated **/

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../app-slice';
import { MatState, Section } from '../redux/mat-state';
import { VoteType } from '../vote-page/vote-slice';

export type PromptData = {
    votesByUser: {
        [userId: string]: {
            [VoteType.Current]?: number
            [VoteType.Future]?: number
        }
    }
};

export type PromptDataState = {
    [promptId: number]: PromptData
};

const initialState: PromptDataState = {};

export type VoteRecord = {
    userId: string
    promptId: number
    voteType: VoteType
    value: number
}

export const promptDataSlice = createSlice({
  name: 'promptData',
  initialState,
  reducers: {
    recordVote: (state, action: PayloadAction<VoteRecord>) => {
      const { userId, promptId, voteType, value } = action.payload;
      const promptData = state[promptId] || (state[promptId] = { votesByUser: {} });
      const votesByUser = promptData.votesByUser;
      const userVotes = votesByUser[userId] || (votesByUser[userId] = {});
      userVotes[voteType] = value;
    },
  },
});

export const { recordVote } = promptDataSlice.actions;

export const getCurrentSection = (state: AppState) => {
  return state.currentSection;
}

export const getAllUsersSelector = (state: MatState) => {
  const users = Array.from(new Set(Object.values(state.promptData).flatMap(data => Object.keys(data.votesByUser))));
  users.sort();
  return users;
}

export const createGetUsersFinishedWithPromptSelector = (promptId: number, voteType: VoteType) => (state: MatState) => {
  const promptData = state.promptData[promptId] || { votesByUser: {} };
  return Object.entries(promptData.votesByUser)
    .filter(([ _, promptData ]) => promptData[voteType] !== undefined)
    .map(([ userId ]) => userId);
}

export const createIsUserDoneWithSectionSelector = (voteType: VoteType, section: Section) => (state: MatState) => {
  return getAllUsersSelector(state)[0];
}