import { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '../redux/create-store';
import './prompt-display.scss';

const getPrompt = (state: RootState) => {
  const promptId = getCurrentPromptId(state);
  return promptId === undefined ? undefined : state.prompts.byId[promptId];
}

const getCurrentSectionName = (state: RootState) => {
  const currentSectionId = state.app.currentSection;
  return currentSectionId ? state.sections.byId[currentSectionId].name : undefined;
}

const getCurrentPromptId = (state: RootState) => {
  return state.app.currentPrompt;
}

const getOrderedSectionPromptIds = ({ prompts }: RootState) => prompts.allIds 

export const PromptDisplay = () => {
  const prompt = useSelector(getPrompt);
  const currentSectionName = useSelector(getCurrentSectionName);
  const orderedSectionPromptIds = useSelector(getOrderedSectionPromptIds, shallowEqual);
  const promptRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  // this useEffect is to alleviate focus issues on vote paging buttons when navigating through prompts
  useEffect(() => {
    promptRef.current.focus();
  }, [prompt])

  return (
    <div className="mat-prompt-display__prompt-wrapper">
      <div className="mat-prompt-display__section-text">{currentSectionName}</div>
      <div className="mat-prompt-display__prompt-text" ref={promptRef}>{prompt?.text || null}</div>
    </div>
  )
}