import { AssessmentInfoPage, Icon } from './assessment-info-page/assessment-info-page';


export const AssessmentExpiredPage = () => {
  const icon = Icon.Clock;
  const header = 'The assessment has expired!'
  const text = 'Your assessment window has expired. Please watch your email for the next opportunity to take this assessment.';
  return (<>
    <AssessmentInfoPage icon={icon} header={header} text={text}/>
  </>)
}