import { useMsal } from '@azure/msal-react';
import { CircularProgress, Typography } from '@mui/material';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { WithAssessment, assessmentConnector } from '../redux/slices/assessment-slice';
import { AssessmentList } from './AssessmentList/assessmentList';
import './lp-facilitator.scss';

type FacilitatorLPProps = WithAssessment;

export const FacilitatorLP: React.FC<FacilitatorLPProps> = (props) => {
  const { getAssessments, data } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {instance} = useMsal();
  const url = '/assessment/create_assessment';

  useEffect(() => {
    setIsLoading(true)
    getAssessments({instance}).then(() => {
      setIsLoading(false)
    })
  }, []);

  if (isLoading) {
    return <div style={{display: 'flex', justifyContent: 'center'}} ><div style={{display: 'flex', alignItems: 'center', color: '#548c3f', gap: '1rem'}}>
      <CircularProgress color="inherit" size={'3.5rem'} />
      <Typography variant="h2" color="#000000">Loading...</Typography>
    </div></div>
  }

  return (
    <div className='mat-lp-fac'>
      <div className='mat-lp-fac__info-container'>
        <h1 className='mat-lp-fac__info-title'>Welcome Back</h1>
        <Button className='mat-lp-fac__card_btn' onClick={() => navigate(url)}>
          Start New Assessment
        </Button>{' '}
        <AssessmentList list={data}/>
      </div>
    </div>
  );
};

export default assessmentConnector(FacilitatorLP);