import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Assessment, AssessmentStatus } from './redux/mat-state';
import { VoteType } from './vote-page/vote-slice';
import { IPublicClientApplication } from '@azure/msal-browser';
import { assessmentStatusByAssesmentAndCurrentUserLink, assessmentStatusByAssesmentLink, loginUserLink } from 'services/api-entrypoints';
import { AssessmentStatusResponse, UserResponse } from 'services/links';
import { buildApi } from 'services/matApiService';
import { ApiVersion } from 'services/media-type-resource-version';
import { AppDispatch, RootState } from 'redux/create-store';
import { connect, ConnectedProps } from 'react-redux';

export type User = {
  id?: string,
  firstName?: string,
  lastName?: string
  email?: string
}

export type AppState = {
    userId?: string,
    user?: User,
    currentCategory?: string,
    currentSection?: string,
    currentPrompt?: string,
    currentVoteState?: VoteType,
    assessmentId?: string,
    currentAssessmentStatus?: AssessmentStatus,
    showVoteWaitPage: boolean
}

const initialState: AppState = {
  currentVoteState: VoteType.Current,
  showVoteWaitPage: false
};

export const getLoginUser = createAsyncThunk<User,{ instance: IPublicClientApplication }>
('app/loginUser', async ({ instance }, thunkApi) => {
 
  const { read: loginUser } = buildApi(instance, ApiVersion.V3_JSON);


  return loginUser(loginUserLink())
    .then((res) => res.json())
    .then((res) => {
      const response = res as UserResponse;
      return  {
        id: response.id,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email
      } as User;
    });
});

export const retrieveAssessmentStatus = createAsyncThunk<AssessmentStatus,{ instance: IPublicClientApplication,  assessmentId: string}>
('app/setCurrentAssessmentStatus', async ({ instance, assessmentId }) => {

  const { read: getAssessmentStatusByAssessment } = buildApi(instance, ApiVersion.V3_JSON);

  let response = await getAssessmentStatusByAssessment(assessmentStatusByAssesmentAndCurrentUserLink(assessmentId));
  if (response.status === 404) {
    const { create: createAssessmentStatus } = buildApi(instance, ApiVersion.V3_JSON);
    console.info('createAssessmentStatus');
    response = await createAssessmentStatus(assessmentStatusByAssesmentLink(assessmentId), {});
  }
  const res = await response.json();
  const assessmentStatusResponse = res as AssessmentStatusResponse;
  return  {
    id: assessmentStatusResponse.id,
    completionTime: assessmentStatusResponse.completionTime,
    startTime: assessmentStatusResponse.startTime,
  } as AssessmentStatus;
});


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAssessment: (state, action: PayloadAction<Partial<Assessment>>) => {
      const assessment = action.payload
      if (assessment.id) {
        state.assessmentId = assessment.id
      }
    },
    changeCurrentCategory: (state, action: PayloadAction<{categoryId: string}>) => {
      const { categoryId } = action.payload;
      return {
        ...state,
        currentCategory: categoryId
      }
    },
    changeCurrentSection: (state, action: PayloadAction<{sectionId: string}>) => {
      const { sectionId } = action.payload;
      return {
        ...state,
        currentSection: sectionId
      }
    },
    changeCurrentPrompt: (state, action: PayloadAction<{promptId: string}>) => {
      const { promptId } = action.payload;
      return {
        ...state,
        currentPrompt: promptId
      }
    },
    showVoteWaitPage: (state, action: PayloadAction<{showVoteWaitPage: boolean, sectionId: string, voteType: VoteType}>) => {
      return {
        ...state,
        showVoteWaitPage: action.payload.showVoteWaitPage
      }
    },
    setUserId: (state, { payload: userId }: PayloadAction<string>) => ({ ...state, userId }),
    setCurrentAssessmentStatus: (state, action: PayloadAction<AssessmentStatus>) => {
      const assessmentStatus = action.payload
      if (assessmentStatus) {
        state.currentAssessmentStatus = assessmentStatus
      }
    },
  },

  extraReducers: 
    (builder) => {
      builder.addCase(getLoginUser.fulfilled, (state: AppState, action) => {
        state.user = action.payload;
      });
      builder.addCase(getLoginUser.pending, (state, action) => {
        console.log('Getting current user...');
      });
      builder.addCase(getLoginUser.rejected, (state, action) => {
        console.warn(action.payload);
      });

      builder.addCase(retrieveAssessmentStatus.fulfilled, (state: AppState, action) => {
        state.currentAssessmentStatus = action.payload;
      });
      builder.addCase(retrieveAssessmentStatus.pending, (state, action) => {
        console.log('Getting AssessmentStatus...');
      });
      builder.addCase(retrieveAssessmentStatus.rejected, (state, action) => {
        console.warn(action.payload);
      });
    },
});

export const {
  changeCurrentCategory,
  changeCurrentSection,
  changeCurrentPrompt,
  showVoteWaitPage,
  setUserId,
  setAssessment,
  setCurrentAssessmentStatus,
} = appSlice.actions;

const mapState = (state: RootState) => state.app;

const mapDispatch = (dispatch: AppDispatch) => ({
  loginUser: (args: { instance: IPublicClientApplication }) => dispatch(getLoginUser(args)),
})

export const appConnector = connect(mapState, mapDispatch);
export type WithApp = ConnectedProps<typeof appConnector>;