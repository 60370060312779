import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { changeCurrentCategory, retrieveAssessmentStatus } from '../../app-slice';
import { GetCategoriesResponse } from '../../model';
import { allCategoriesLink } from '../../services/api-entrypoints';
import { buildApi } from '../../services/matApiService';
import { ApiVersion } from '../../services/media-type-resource-version';
import { AppDispatch, RootState } from '../create-store';
import { Category } from '../mat-state';
import { getSectionsByCategories } from './section-slice';

export type CategoryState = {
  byId: {
    [id: string]: Category;
  };
  allIds: string[];
};

const initialState: CategoryState = {
  byId: {},
  allIds: [],
};

type GetCategoryBySectionArgs = {
  instance: IPublicClientApplication;
  assessmentId: string;
}

export const getCategories = createAsyncThunk<
  Category[],
  { instance: IPublicClientApplication; assessmentId: string }
>(
  'categories/getCategories',
  async ({ instance, assessmentId }: GetCategoryBySectionArgs, thunkApi) => {
    try {
      const { read: getCategories } = buildApi(instance, ApiVersion.V3_JSON);
      const result = await getCategories(allCategoriesLink(assessmentId))
        .then((res) => res.json())
        .then((res) => {
          const response = res as GetCategoriesResponse
          const categories = response.items
            .sort((c1, c2) => c1.order - c2.order)
            .map(c => {
              return {
                id: c.id,
                name: c.name,
                icon: c.icon,
                order: c.order,
                assessmentId: assessmentId
              }
            }) as Category[]
          
          if (categories.length != 0) {
            const categoryIds = categories.map((category) => category.id);
            const categoryId = categoryIds[0];
            thunkApi.dispatch(getSectionsByCategories({ instance, categoryIds }));
            thunkApi.dispatch(changeCurrentCategory({ categoryId }));
            thunkApi.dispatch(retrieveAssessmentStatus({instance, assessmentId}));
          }
          return categories;
        });
      return thunkApi.fulfillWithValue(result);  
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategories: (state, action: PayloadAction<Category[]>) => {
      action.payload.forEach((category) => {
        if (!state.byId[category.id]) {
          state.allIds.push(category.id);
        }
        state.byId[category.id] = category;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, 
      (state: CategoryState, action) => {
        const payload = action.payload;

        const finalState: CategoryState = {
          byId: {},
          allIds: [],
        };

        for (const category of payload) {
          finalState.byId[category.id] = category;
          finalState.allIds.push(category.id);
        }

        state.allIds = finalState.allIds;
        state.byId = finalState.byId;
      });
    builder.addCase(getCategories.pending, (state: CategoryState, action) => {
      console.log('getCategories pending ', action.payload);
    });
    builder.addCase(getCategories.rejected, (state: CategoryState, action) => {
      console.log('getCategories rejected ', action.payload);
    });
  },
});

const mapState = (state: RootState) => state.categories;
const mapDispatch = (dispatch: AppDispatch) => ({
  getCategories: (args: GetCategoryBySectionArgs) => dispatch(getCategories(args))
})
export const categoryConnector = connect(mapState, mapDispatch);
export type WithCategory = ConnectedProps<typeof categoryConnector>;
export const { addCategories } = categoriesSlice.actions;
