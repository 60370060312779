import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { CreateAssessmentPayload, GetAssessmentsResponse } from '../../model';
import { addAssessmentLink, getAssessmentLink } from '../../services/api-entrypoints';
import { getAssessments as getAssessmentsApi } from '../../services/api-service';
import { buildApi } from '../../services/matApiService';
import { AppDispatch, RootState } from '../create-store';
import { Assessment } from '../mat-state';

export type AssessmentState = {
  data: Assessment[];
  token: string | undefined
}

const initialState: AssessmentState = {
  data: [],
  token: localStorage.getItem('access_token') || undefined,
}

type AddAssessmentArgs = {
  data: CreateAssessmentPayload;
  instance: IPublicClientApplication;
}

type GetAssessmentArgs = {
  assessmentId: string;
  instance: IPublicClientApplication;
}

type GetAssessmentsArgs = {
  instance: IPublicClientApplication;
}

export const getAssessments = createAsyncThunk(
  // TODO: Update this when API endpoint is updated
  'assessment/getAssessments',
  async (args: GetAssessmentsArgs, thunkAPI) => {
    if(args.instance) {
      const response = await getAssessmentsApi(args.instance)

      if (response.ok) {
        return thunkAPI.fulfillWithValue(await response.json() as GetAssessmentsResponse);
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error retrieving assessments: ${response.statusText}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error retrieving assessments.'));
  }
)

export const getAssessment = createAsyncThunk(
  'assessment/getAssessment',
  async (args: GetAssessmentArgs, thunkAPI) => {
    if (args.instance) {
      const { read: getLink } = buildApi(args.instance);

      const response = await getLink(getAssessmentLink(args.assessmentId));

      if (response.ok) {
        return thunkAPI.fulfillWithValue(await response.json().then(res => res as Assessment));
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error getting assessment: ${args.assessmentId}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error retrieving assessment.'));
  }
)

export const addAssessment = createAsyncThunk(
  // TODO: Update this when API endpoint is updated
  'assessment/addAssessment',
  async (args: AddAssessmentArgs, thunkAPI) => {

    if (args.instance) {
      const {create: postLink} = buildApi(args.instance)

      const addAssessmentResponse = await postLink(addAssessmentLink, args.data);

      if (addAssessmentResponse.ok) {
        return thunkAPI.fulfillWithValue(await addAssessmentResponse.json().then(res => res) as Assessment);
      } else {
        return thunkAPI.rejectWithValue(new Error(`Error saving new assessment: ${addAssessmentResponse.statusText}`));
      }
    }
    return thunkAPI.rejectWithValue(new Error('Error saving new assessment.'));
  })

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: { },
  extraReducers(builder) {
    builder.addCase(addAssessment.fulfilled, (state, action) => {
      console.log(state, action)
      return {
        data: [ ...state.data, action.payload],
        token: state.token
      }
    });
    builder.addCase(addAssessment.rejected, (state, action) => {
      console.log(state, action)
    });
    builder.addCase(getAssessments.fulfilled, (state, action) => {
      return {
        data: action.payload.items,
        token: state.token
      }
    });
    builder.addCase(getAssessments.pending, (state, action) => {
      console.log(state, action)
    });
    builder.addCase(getAssessments.rejected, (state, action) => {
      console.log(state, action)
    });
    builder.addCase(getAssessment.fulfilled, (state, action) => {
      console.log(state, action);
      if (!state.data.includes(action.payload)) {
        return {
          data: [...state.data, action.payload],
          token: state.token
        };
      }
    });
    builder.addCase(getAssessment.pending, (state, action) => {
      console.log(state, action);
    });
    builder.addCase(getAssessment.rejected, (state, action) => {
      console.log(state, action);
    })
  },
});

const mapState = (state: RootState) => state.assessment;

const mapDispatch = (dispatch: AppDispatch) => ({
  getAssessments: (args: GetAssessmentsArgs) => dispatch(getAssessments(args)),
  addAssessment: (args: AddAssessmentArgs) => dispatch(addAssessment(args)),
  getAssessment: (args: GetAssessmentArgs) => dispatch(getAssessment(args))
})

export const assessmentConnector = connect(mapState, mapDispatch);
export type WithAssessment = ConnectedProps<typeof assessmentConnector>;