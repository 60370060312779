import { useSelector } from 'react-redux';
import type { RootState } from '../redux/create-store';
import { ParticipantWR } from './participantWR';
import { FacilitatorWR } from './facilitatorWR';
import { UserType } from '../redux/mat-state';
import './waiting-room.scss'

const getUserType = (state: RootState) => {
  return state.login.userType;
}


export const WaitingRoom = () => {
  const userType = useSelector(getUserType);
  return (
    <>
      <div className="mat-wr__main">
        { userType === UserType.Facilitator
          ? <FacilitatorWR />
          :
          <ParticipantWR />
        }
      </div>
    </>
  )
}