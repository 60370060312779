import { combineReducers } from '@reduxjs/toolkit';
import { appSlice } from '../app-slice';
import { promptDataSlice } from '../assessment/prompt-data-slice';
import { sectionDataSlice } from '../assessment/section-data-slice';
import { loginSlice } from './slices/login-slice';
import { voteSlice } from '../vote-page/vote-slice';
import { assessmentSlice } from './slices/assessment-slice';
import { categoriesSlice } from './slices/category-slice';
import { promptsSlice } from './slices/prompt-slice';
import { sectionsSlice } from './slices/section-slice';
import { labelsSlice } from './slices/label-slice';

export const reducer = combineReducers({
  app: appSlice.reducer, // general metadata - facilitator vs participant
  assessment: assessmentSlice.reducer,
  categories: categoriesSlice.reducer,
  sections: sectionsSlice.reducer,
  prompts: promptsSlice.reducer,
  labels: labelsSlice.reducer,
  promptData: promptDataSlice.reducer, // most probably deprecated
  sectionData: sectionDataSlice.reducer, // most probably deprecated
  votesByPromptId: voteSlice.reducer,
  login: loginSlice.reducer,
});

