/** This file is most probably deprecated **/

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatState, Section } from '../redux/mat-state';
import { VoteType } from '../vote-page/vote-slice';

export type SectionData = {
    sectionFinishedByUser: {
        [userId: string]: {
            [VoteType.Current]?: boolean
            [VoteType.Future]?: boolean
        }
    }
};

export type SectionDataState = {
    [sectionId: string]: SectionData
};

const initialState: SectionDataState = {};

export type FinishSection = {
    userId: string
    sectionId: string
    voteType: VoteType
}

type SectionList = {
  items: Section[];
};

export const sectionDataSlice = createSlice({
  name: 'sectionData',
  initialState,
  reducers: {
    finishSection: (state, action: PayloadAction<FinishSection>) => {
      const { userId, sectionId, voteType  } = action.payload;
      const sectionData = state[sectionId] || (state[sectionId] = { sectionFinishedByUser: {} });
      const sectionFinishedByUser = sectionData.sectionFinishedByUser;
      const userSectionStatuses = sectionFinishedByUser[userId] || (sectionFinishedByUser[userId] = {});
      userSectionStatuses[voteType] = true;
    },
  },
});

export const { finishSection } = sectionDataSlice.actions;

export const createGetUsersFinishedWithSectionSelector = (sectionId: string | undefined, voteType: VoteType) => (state: MatState) => {    
  const sectionData = state.sectionData[sectionId !== undefined ? sectionId : 0];
  if (sectionData) {
    return Object.entries(sectionData.sectionFinishedByUser)
      .filter(([ userId, finishStatus ]) => finishStatus[voteType] === true)
      .map(([ userId ]) => userId);
  } else {
    return [];
  }
}
