import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Section } from 'redux/mat-state';
import { changeCurrentCategory, changeCurrentPrompt, changeCurrentSection } from '../app-slice';
import type { RootState } from '../redux/create-store';
import { getCategories } from '../redux/slices/category-slice';
import './outline.scss';

const expandCategories = (state: RootState) => {
  const categoryIds = state.categories.allIds; //get all category ids
  return categoryIds.map((categoryId) => state.categories.byId[categoryId]); //create new array of mapped category ids
}

const getPromptsAmountForSectionId = (state: RootState, sectionId: string) => {
  const allPromptIds = state.prompts.allIds
  const allPrompts = allPromptIds.map(id => state.prompts.byId[id])
  return allPrompts.filter(prompt => prompt.sectionId === sectionId).reduce((output) => output + 1, 0)
}

const getSectionsAmountForCategoryId = (state: RootState, categoryId: string) => {
  const allSectionIds = state.sections.allIds
  const allSections = allSectionIds.map(id => state.sections.byId[id])
  return allSections.filter(section => section.categoryId === categoryId).reduce((output) => output + 1, 0)
}

const getCompletedSectionIds = (state: RootState) => {
  type SectionsData = {
    [sectionId: string]: {
      completedPrompts: number,
      promptsAmount: number
    }
  };

  const completedPromptIds = Object.keys(state.votesByPromptId);

  const sectionsData = completedPromptIds.reduce((output: SectionsData, promptId) => {
    const prompt = state.prompts.byId[promptId];
    const sectionId = prompt.sectionId;
    const promptsAmount = getPromptsAmountForSectionId(state, sectionId)
    const completedPrompts = output[sectionId] ? output[sectionId].completedPrompts + 1 : 1

    output[sectionId] = {
      completedPrompts: completedPrompts,
      promptsAmount: promptsAmount
    }
    return output;
  }, {});
  const completedSectionIds = [];

  for (const sectionId in sectionsData) {
    const sectionData = sectionsData[sectionId];

    if (sectionData.promptsAmount === sectionData.completedPrompts) {
      completedSectionIds.push(sectionId);
    }
  }
  return completedSectionIds;
}

const getCompletedCategoriesIds = (state: RootState) => {
  type CategoriesData = {
    [categoryId: string]: {
      completedSections: number,
      sectionsAmount: number
    }
  };

  const completedSectionIds = getCompletedSectionIds(state);

  const categoriesData = completedSectionIds.reduce((output: CategoriesData, sectionId) => {
    const sections = state.sections.byId[sectionId];
    const categoryId = sections.categoryId;
    const sectionsAmount = getSectionsAmountForCategoryId(state, categoryId)
    const completedSections = output[categoryId] ? output[categoryId].completedSections + 1 : 1

    output[categoryId] = {
      completedSections: completedSections,
      sectionsAmount: sectionsAmount
    }
    return output;
  }, {});

  const completedCategoryIds: string[] = [];

  for (const categoryId in categoriesData) {
    const categoryData = categoriesData[categoryId];

    if (categoryData.sectionsAmount === categoryData.completedSections) {
      completedCategoryIds.push(categoryId);
    }
  }
  return completedCategoryIds;
}

const getCurrentCategoryId = (state: RootState) => {
  return state.app.currentCategory;
}

const getSectionsForCategories = (state: RootState) => {
  type ResponseType = {
    [categoryId: string]: Section[]
  }
  const allSectionIds = state.sections.allIds
  const allSections = allSectionIds.map(id => state.sections.byId[id])
  
  return allSections.reduce((output: ResponseType, current: Section) => {
    if (!output[current.categoryId]) {
      output[current.categoryId] = [current]
    } else {
      output[current.categoryId].push(current)
    }

    return output
  }, {})
}

const getFirstPromptsForSections = (state: RootState) => {
  type ResponseType = {
    [sectionId: string]: Prompt
  }
  const allPromptIds = state.prompts.allIds
  const allPrompts = allPromptIds.map(id => state.prompts.byId[id])
  
  return allPrompts.reduce((output: ResponseType, current: Prompt) => {
    if (!output[current.sectionId] || output[current.sectionId].order > current.order) {
      output[current.sectionId] = current
    }

    return output
  }, {})
}

const getCurrentSectionId = (state: RootState) => {
  return state.app.currentSection;
}

const getOrderedSections = (categoryId: string) => (state: RootState) => {
  return state.sections.allIds
    .map(sectionId => state.sections.byId[sectionId])
    .filter(({ categoryId: catId }) => categoryId === catId);
};

interface Param {
  assessmentId: string;
}

const Outline = (props: Param) => {
  const dispatch = useDispatch(); 
  const { instance,  } = useMsal();
  const [collapsedCategory, setCollapsedCategory] = useState<string>('');

  const currentCategoryId = useSelector(getCurrentCategoryId);
  const currentSectionId = useSelector(getCurrentSectionId);
  const sectionsForCategories = useSelector(getSectionsForCategories);
  const firstPromptsForSections = useSelector(getFirstPromptsForSections);
  const completedCategoryIds = useSelector(getCompletedCategoriesIds);

  useEffect(() => {
    dispatch(getCategories({instance, assessmentId: props.assessmentId}));

  }, []);
  useEffect(() => {
    setCollapsedCategory('')
  }, [currentSectionId])

  const onCategoryClick = (categoryId: string) => {
    if (currentCategoryId === categoryId) {
      setCollapsedCategory((prevValue: string) => prevValue === categoryId ? '' : categoryId)
    } else {
      setCollapsedCategory('')
      dispatch(changeCurrentCategory({categoryId: categoryId}));

      if (!sectionsForCategories[categoryId].find(s => s.id === currentSectionId)) {
        const firstSectionId = sectionsForCategories[categoryId][0].id
        dispatch(changeCurrentSection({sectionId: firstSectionId}));
        dispatch(changeCurrentPrompt({promptId: firstPromptsForSections[firstSectionId].id}));
      }
    }
  }

  return (
    <>
      <div className='mat-outline__wrapper' data-testid='outline'>
        <ul className='mat-outline__categories'>
          {useSelector(expandCategories).map(({ name, id}) => {
            const isCurrent = (id === currentCategoryId) && (currentCategoryId !== collapsedCategory)
            const isCompleted = (categoryId: string) => {
              return completedCategoryIds.indexOf(categoryId) !== -1;
            }

            return (
              <li key={id} className='mat-outline__outline mat-outline__outline-category'>
                <div className={isCurrent ? 'mat-outline__current-category-active mat-outline__category-clickable' : 'mat-outline__category-clickable'} onClick={() => onCategoryClick(id)}>
                  <div style={{width: '95%'}}>
                    <span className={isCurrent ? 'mat-outline__category-active-icon pi pi-bars' : 'mat-outline__category-icon pi pi-bars'}></span>
                    {name}
                  </div>
                  <div style={{width: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <span className={isCompleted(id) ? 'pi pi-check mat-outline__pi-check' : !isCurrent ? 'mat-outline__category-collapse-status-indicator pi pi-chevron-down' : 'mat-outline__category-collapse-status-indicator pi pi-chevron-up'}></span>
                  </div>
                </div>
                {isCurrent ?
                  <CategoryOutline setCollapsedCategory={setCollapsedCategory} categoryId={id} />
                  : null
                }
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
};

const CategoryOutline = ({ categoryId, setCollapsedCategory }: { categoryId: string, setCollapsedCategory: React.Dispatch<React.SetStateAction<string>> }) => {
  const dispatch = useDispatch(); 
  const currentSectionId = useSelector(getCurrentSectionId);
  const firstPromptsForSections = useSelector(getFirstPromptsForSections);
  const completedSectionIds = useSelector(getCompletedSectionIds);
  const sectionIds = useSelector(getOrderedSections(categoryId));

  const onSectionClick = (sectionId: string) => {
    if (currentSectionId !== sectionId) {
      setCollapsedCategory('')
      dispatch(changeCurrentSection({sectionId: sectionId}))
      dispatch(changeCurrentPrompt({promptId: firstPromptsForSections[sectionId].id}))
    }
  }

  return (
    <div className='mat-outline__expanded-category-line'>
      <ul className='mat-outline__expanded-category'>
        {sectionIds.map(({ name, id, order }) => {

          const isCompleted = (sectionId: string) => {
            return completedSectionIds.indexOf(sectionId) !== -1;
          }

          return <li key={id} onClick={() => onSectionClick(id)}
            className={id === currentSectionId ?
              'mat-outline__outline mat-outline__outline-section mat-outline__current-section' :
              currentSectionId && id < currentSectionId ?
                'mat-outline__outline mat-outline__outline-section mat-outline__complete-section' :
                'mat-outline__outline mat-outline__outline-section'}>
            <div style={{width: '90%'}}>
              {name}
            </div>
            <div style={{width: '10%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <span className={isCompleted(id) ? 'pi pi-check mat-outline__pi-check' : ''}></span>
            </div>
            <div className='section-connector'></div>
            {(order === sectionIds.length) && <div className='section-connector section-connector__last'></div>}
          </li>
        })}
      </ul>
    </div>
  )
};

export default Outline;