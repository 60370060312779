import { envConfig } from '../env-config';
import {
  AssessmentResponse,
  CategoryResponse,
  Link,
  PromptResponse,
  LabelResponse,
  SectionResponse,
  UserResponse,
  AssessmentStatusResponse,
} from './links';

const createLink = (
  baseUrl = '/',
  baseResourcePath: string,
  id: string | number
) => ({
  href: `${baseUrl}${baseResourcePath}/${id}`,
});

const buildLink = (baseUrl = '/', ...collection: string[]) => ({
  href: `${baseUrl}${collection.join('/')}`,
});

export const createAssessmentLink = (
  assessmentId: number
): Link<AssessmentResponse> =>
  createLink(envConfig.apiBaseUrl, 'assessments', assessmentId);

export const addAssessmentLink: Link<AssessmentResponse> = {
  // TODO: When API endpoint changed update this link
  href: `${envConfig.apiBaseUrl}assessments`,
};

export const allAssessmentsLink: Link<AssessmentResponse[]> = {
  // TODO: When API endpoint changed update this link
  href: `${envConfig.apiBaseUrl}assessments`,
};

export const getAssessmentLink = (
  assessmentId: string
): Link<AssessmentResponse> => 
  buildLink(envConfig.apiBaseUrl, 'assessments', assessmentId);

export const allCategoriesLink = (
  assessmentId: string
): Link<CategoryResponse[]> =>
  buildLink(envConfig.apiBaseUrl, 'assessments', assessmentId, 'categories');

export const sectionByCategoryLink = (
  categoryId: string
): Link<SectionResponse[]> =>
  buildLink(envConfig.apiBaseUrl, 'categories', categoryId, 'sections');

export const promptBySectionLink = (
  sectionId: string
): Link<PromptResponse[]> =>
  buildLink(envConfig.apiBaseUrl, 'sections', sectionId, 'prompts');


export const labelByPromptLink = (
  promptId: string
): Link<LabelResponse[]> =>
  buildLink(envConfig.apiBaseUrl, 'prompts', promptId, 'labels');
  
export const recordVoteLink = (
  labelId: string
): Link<PromptResponse> =>
  buildLink(envConfig.apiBaseUrl, 'labels', labelId, 'prompt_results');

  
export const assessmentStatusByAssesmentLink = (
  assessmentId: string
): Link<AssessmentStatusResponse[]> =>
  buildLink(envConfig.apiBaseUrl, 'assessments', assessmentId, 'assessments_status');

export const loginUserLink = (): Link<UserResponse> => buildLink(envConfig.apiBaseUrl, 'users', 'me');

export const assessmentStatusByAssesmentAndCurrentUserLink = (
  assessmentId: string
): Link<AssessmentStatusResponse> =>
  buildLink(envConfig.apiBaseUrl, 'assessments', assessmentId, 'assessments_status:login_user');

export const assessmentStatusLink = (
  assessmentStatusId: string
): Link<AssessmentStatusResponse[]> =>
  buildLink(envConfig.apiBaseUrl, 'assessments_status', assessmentStatusId);

