import './grid.scss';

const Grid = () => {

  return (
    <>
      <button className="mat-grid__outline">
                outline
      </button>
      <button className="mat-grid__title">
        <h2>Participants Progress</h2>  
      </button>
      <button className="mat-grid__main">
        <div style={{width: '800px;', padding: '50px'}}>
                   main area
        </div>
      </button>
      <button className="mat-grid__actions">
                actions
      </button>
    </>
  )
}

export default Grid;