import * as yup from 'yup';

export const generalValidation = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  client: yup.string(),
  sponsor: yup.string(),
  startDate: yup.date().required('Required'),
  endDate: yup.date().required(),
  finalizationDate: yup.date().required()
});

export default generalValidation;