import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { GetLabelsResponse } from '../../model';
import { labelByPromptLink } from '../../services/api-entrypoints';
import { buildApi } from '../../services/matApiService';
import { ApiVersion } from '../../services/media-type-resource-version';
import { AppDispatch, RootState } from '../create-store';
import { Label } from '../mat-state';
import { getVoteByLabel } from 'vote-page/vote-slice';

export type LabelState = {
  byId: {
    [id: string]: Label;
  };
  allIds: string[];
};

const initialState: LabelState = {
  byId: {},
  allIds: [],
};

type getLabelByPromptArgs = {
  instance: IPublicClientApplication;
  promptIds: string[];
}

export const getLabelByPrompt = createAsyncThunk<
  Label[],
  { instance: IPublicClientApplication; promptIds: string[] }
>('prompt/getLabelByPrompt', async ({ instance, promptIds }: getLabelByPromptArgs, thunkApi) => {
  try {
    const { read: getLabels } = buildApi(instance, ApiVersion.V3_JSON);

    const result = await Promise.all(
      promptIds.map((promptId) => {
        return getLabels(labelByPromptLink(promptId.toString()))
          .then((res) => res.json())
          .then((res) => {
            const response = res as GetLabelsResponse;
            const labels = response.items
              .sort((l1, l2) => l1.order - l2.order)
              .map(l => {
                return {
                  id: l.id,
                  label: l.label,
                  weight: l.weight,
                  order: l.order,
                  promptId: promptId
                } as Label
              })
            const labelIds = labels.map(l => l.id);
            thunkApi.dispatch(
              getVoteByLabel({ instance, labelIds: labelIds, promptId: promptId })
            );
            return labels;
          });
      })
    ).then((labelListArray) => {
      return labelListArray.flatMap(l => l);
    });
    return thunkApi.fulfillWithValue(result);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      getLabelByPrompt.fulfilled,
      (state: LabelState, action) => {
        const labelList = action.payload;
        
        const finalState: LabelState = {
          byId: {},
          allIds: [],
        };

        for (const label of labelList) {
          finalState.byId[label.id] = label;
          finalState.allIds.push(label.id);
        }

        state.allIds = finalState.allIds;
        state.byId = finalState.byId;
      }
    );
    builder.addCase(
      getLabelByPrompt.rejected,
      (state: LabelState, action) => {
        console.log('getLabelByPrompt rejected ', action.payload);
      }
    );

    builder.addCase(
      getLabelByPrompt.pending,
      (state: LabelState, action) => {
        console.log('getLabelByPrompt pending ', action.payload);
      }
    );

  },
});

const mapState = (state: RootState) => state.labels;

const mapDispatch = (dispatch: AppDispatch) => ({
  getLabelByPrompt: (args: getLabelByPromptArgs) => dispatch(getLabelByPrompt(args))
})

export const labelConnector = connect(mapState, mapDispatch);

export type WithLabel = ConnectedProps<typeof labelConnector>;

