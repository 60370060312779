import { createSlice } from '@reduxjs/toolkit';
import { UserType } from '../mat-state';

export type LoginState = {
    userType: UserType
    token: string | undefined
}

const initialState: LoginState = {
  userType: localStorage.getItem('access_token') ? UserType.Facilitator : UserType.Participant,
  token: localStorage.getItem('access_token') || undefined,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
})