import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {reducer} from './reducer'
import {useDispatch as _useDispatch, useSelector as _useSelector} from 'react-redux';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useSelector = (selector: (state: RootState) => any) => _useSelector(selector)
export const useDispatch = () => _useDispatch<typeof store.dispatch>()
