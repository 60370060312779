import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../redux/create-store';
import { WithVote, voteConnector } from '../vote-slice';
import { VotePaging } from '../vote-paging';
import { PromptDisplay } from '../../shared-components/prompt-display';
import './vote-block.scss'
import { RadioList } from '../../shared-components/radio-list/radio-list';
import { useMsal } from '@azure/msal-react';

const getCurrentPromptId = (state: RootState) => {
  return state.app.currentPrompt;
}

const getPrompt = (state: RootState) => {
  const promptId = getCurrentPromptId(state);
  return promptId === undefined ? undefined : state.prompts.byId[promptId];
}

const getVotedLabelId = (state: RootState) => {
  const promptId = getCurrentPromptId(state);
  if (promptId && state.votesByPromptId[promptId]?.labelId) {
    return state.votesByPromptId[promptId]?.labelId
  } else {
    return undefined
  }
}

const getLabels = (state: RootState) => {
  const promptId = getCurrentPromptId(state);
  return state.labels.allIds
    .map(labelId => state.labels.byId[labelId])
    .filter(label => label.promptId === promptId)
}

type VoteBlockProps = WithVote;

export const VoteBlock: React.FC<VoteBlockProps> = (props) => {
  const { recordVote } = props
  const { instance } = useMsal();
  const promptId = useSelector(getCurrentPromptId);
  const prompt = useSelector(getPrompt);
  const selectedLabelId = useSelector(getVotedLabelId);
  const labels = useSelector(getLabels);

  if (promptId == null || prompt === undefined) {
    return null;
  }

  const setSelectedValue = (value: string) => {
    recordVote({
      instance: instance,
      labelId: value,
      promptId: promptId
    }).then(res => {
      if (res.type === 'votesByPromptId/recordVote/rejected') {
        window.alert('Could not select this response!')
      }
    });
  }

  return (
    <div className="mat-vote-block__wrapper">
      <PromptDisplay />
      <div className='mat-vote-block__buttons'>
        <RadioList
          elements={labels}
          selectedValue={selectedLabelId}
          setSelectedValue={setSelectedValue}
        />
      </div>
    </div>
  );  
};

export default voteConnector(VoteBlock)
