type EnvConfig = {
    apiBaseUrl?: string
    loginPageUrl?: string
    loginClientId?: string
    loginAuthority?: string
};

const loadEnvConfig: () => EnvConfig = () => {
  const envConfigJson = document.getElementById('env-config')?.innerHTML;
  if (envConfigJson) {
    try {
      const parsed: unknown = JSON.parse(envConfigJson);
      if (parsed && typeof parsed === 'object' && !Array.isArray(parsed)) {
        return parsed;
      }
      console.warn('#env-config element contains invalid data');
    } catch (e) {
      console.warn('#env-config element contains invalid JSON');
    }
  } 
  else if (process.env) {
    return {
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
      loginPageUrl: process.env.REACT_APP_LOGIN_PAGE_URL,
      loginClientId:  process.env.REACT_APP_LOGIN_CLIENT_ID,
      loginAuthority: process.env.REACT_APP_LOGIN_AUTHORITY,
    };
  }
  console.warn('envConfig is unavailable');
  return {};
};

export const envConfig = loadEnvConfig();
